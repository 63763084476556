import React, { PureComponent, Fragment } from 'react'
import styled from 'styled-components'
import { Moment } from 'moment'
import { Rates, Room } from './Room'

const ROOMS: Rates[] = [
  {
    name: 'Basic single',
    price: 59,
    benefits: [
      'There is a bed, although not very big',
      'Private bathroom with possibility to shower',
      'Some rooms have a window',
      'Wifi available',
    ],
  },
  {
    name: 'Nice spacious king',
    price: 189,
    benefits: [
      'Large bed perfect for 2 people',
      'Private bathroom with possibility to shower',
      'Enough space to put down your suitcase',
      'Wifi available',
    ],
  },
  {
    name: 'Executive suite',
    price: 399,
    benefits: [
      'Extra Large bed perfect for 2 people',
      'Private bathroom with shower and bath tub',
      'Seating area with sofa and tv',
      'Wifi available',
    ],
  },
]

const UserFeedbackContainer = styled.div`
  margin-top: 30px;
  padding: 15px;
  background-color: darkolivegreen;
  color: white;
`

const RoomsContainer = styled.div`
  padding: 15px;
  border: 1px solid darkolivegreen;
`

type Props = {
  language: string
  startDate: Moment | null
  endDate: Moment | null
}

export class RoomsAndRates extends PureComponent<Props> {
  render() {
    const { language, startDate, endDate } = this.props
    return (
      <Fragment>
        <UserFeedbackContainer>
          {startDate && endDate
            ? `You are looking at rooms to stay between ${startDate.format('DD/MM/YY')} and ${endDate.format(
                'DD/MM/YY',
              )}`
            : `Please select some dates to see availability`}
        </UserFeedbackContainer>
        {startDate && endDate && (
          <Fragment>
            <div itemScope itemType="https://structured-data.triptease.io/LodgingSearch">
              <meta itemProp="checkinTime" content={startDate.toISOString()} />
              <meta itemProp="checkoutTime" content={endDate.toISOString()} />
              <meta itemProp="numAdults" content="2" />
              <meta itemProp="numChildren" content="0" />
              <meta itemProp="numRooms" content="1" />
            </div>
            <RoomsContainer>
              {ROOMS.map((room, index) => (
                <Fragment>
                  <Room key={index} name={room.name} price={room.price} benefits={room.benefits} language={language} />
                  {index === 0 && <div className="price-fighter-widget" />}
                </Fragment>
              ))}
            </RoomsContainer>
          </Fragment>
        )}
      </Fragment>
    )
  }
}
