import moment from 'moment'

export const createPricingAPIResponse = (directPrice: number, currency: string) => {
  const now = moment()
  const inFifteenMinutes = now.add(15, 'minutes')

  return {
    _id: '_id',
    _links: {
      self: '',
      status: '',
    },
    display: { render: 'hidden' },
    result: {
      bar: [
        {
          name: 'Booking.com',
          ota: 'booking',
          complete: true,
          price: directPrice + 10,
          currency,
          displayCurrency: currency,
          currencyCode: currency,
          priceDifference: 10,
          displayPrice: `${currency} ${directPrice + 10}`,
        },
        {
          name: 'Expedia.com',
          ota: 'expedia',
          complete: true,
          price: directPrice + 15,
          currency,
          displayCurrency: currency,
          currencyCode: currency,
          priceDifference: 15,
          displayPrice: `${currency} ${directPrice + 15}`,
        },
        {
          name: 'Hotels.com',
          ota: 'hotels',
          complete: true,
          price: directPrice + 20,
          currency,
          displayCurrency: currency,
          currencyCode: currency,
          priceDifference: 20,
          displayPrice: `${currency} ${directPrice + 20}`,
        },
        { name: 'Agoda.com', ota: 'agoda', complete: true, price: null },
      ],
      full: false,
      complete: true,
      direct: directPrice,
      directTrue: directPrice,
      wait: 1000,
      fetched: now.format(),
      validUntil: inFifteenMinutes.format(),
      directCurrency: currency,
      directPrice: `${currency} ${directPrice}`,
    },
    ticketId: 'ticketId',
    complete: true,
    directPrice: { value: directPrice, currency },
    prices: [
      {
        name: 'Booking.com',
        agent: 'booking',
        ota: 'booking',
        complete: true,
        fetchedTime: now.format(),
        price: { value: directPrice + 10, currency },
      },
      {
        name: 'Expedia.com',
        agent: 'expedia',
        ota: 'expedia',
        complete: true,
        fetchedTime: now.format(),
        price: { value: directPrice + 15, currency },
      },
      {
        name: 'Hotels.com',
        agent: 'hotels',
        ota: 'hotels',
        complete: true,
        fetchedTime: now.format(),
        price: { value: directPrice + 20, currency },
      },
      { name: 'Agoda.com', agent: 'agoda', ota: 'agoda', complete: true, price: null },
    ],
    callbackDelay: 1000,
  }
}
