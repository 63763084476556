import React, { PureComponent } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  margin-bottom: 30px;
  padding: 15px;
  background-color: palegreen;
`

const InlineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.span`
  background-color: darkslategray;
  color: white;
  padding: 5px 10px;
  font-weight: bold;
`

const Price = styled.span`
  font-size: 2em;
`

const Benefits = styled.ul`
  padding: 10px 0 0 15px;
  list-style-type: square;
  list-style-position: inside;
`

export type Rates = {
  name: string
  price: number
  benefits: string[]
}

type ComponentProps = {
  language: string
}

type Props = ComponentProps & Rates

export class Room extends PureComponent<Props> {
  render() {
    const { name, price, benefits, language } = this.props
    const displayPrice = new Intl.NumberFormat(language, {
      style: 'currency',
      currency: 'GBP',
      currencyDisplay: 'symbol',
    }).format(price)

    return (
      <Container>
        <span itemScope itemType="http://schema.org/Offer">
          <InlineContainer>
            <span itemProp="itemOffered" itemScope itemType="http://schema.org/HotelRoom">
              <Title>{name}</Title>
              <meta itemProp="identifier" content="DD-001" />
            </span>
            <span>
              <Price>{displayPrice}</Price>
              <meta itemProp="identifier" content="BAR-001" />
              <span itemProp="priceSpecification" itemScope itemType="http://schema.org/UnitPriceSpecification">
                <meta itemProp="price" content={price.toFixed(2)} />
                <meta itemProp="priceCurrency" content="GBP" />
              </span>
            </span>
          </InlineContainer>
        </span>
        <Benefits>
          {benefits.map((benefit, index) => (
            <li key={index}>{benefit}</li>
          ))}
        </Benefits>
      </Container>
    )
  }
}
