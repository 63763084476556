import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { Header } from './Header'
import { Body } from './Body'
import { PriceCheckApiInterceptor } from '../priceCheckApiInterceptor/PriceCheckApiInterceptor'

export function Page() {
  const { integrationId, hotelName, language, realPriceCheckApi } = useParams()
  return (
    <Fragment>
      <Helmet htmlAttributes={{ lang: language! }}>
        <title>{integrationId}</title>
        <script>{`(function(a,t,b,c,d){c=a.createElement(t);c.src=b,c.defer=true,c.async=true,c.crossOrigin='anonymous';c.type="text/javascript";d=a.getElementsByTagName(t)[0];d.parentNode.insertBefore(c,d)})(document,"script","https://onboard.triptease.io/bootstrap.js?integrationId=${integrationId}");`}</script>
      </Helmet>
      <div>
        <Header integrationId={integrationId!} hotelName={hotelName!} language={language!} />
        <Body language={language!} />
        {!realPriceCheckApi && <PriceCheckApiInterceptor />}
      </div>
    </Fragment>
  )
}
