export const createFetchResponse = (body: object) => {
  return {
    status: 200,
    text: () => Promise.resolve(JSON.stringify(body)),
    headers: {
      entries: () => [
        ['content-type', 'application/json; charset=utf-8'],
        ['cache-control', 'max-age=0'],
      ],
    },
  }
}
