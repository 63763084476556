import { PureComponent } from 'react'
import { createPricingAPIResponse } from './createPricingAPIResponse'
import { createFetchResponse } from './createFetchResponse'

const priceCheckPostRequestURL = 'https://price-check.pf.triptease.io/price-check'

type PCRequestBody = {
  rateDetails: {
    price: number
    currency: string
  }[]
}

export class PriceCheckApiInterceptor extends PureComponent {
  componentDidMount(): void {
    ;(function(ns, fetch) {
      if (typeof fetch !== 'function') return

      ns.fetch = function() {
        if (arguments[0].includes(priceCheckPostRequestURL)) {
          const requestBody: PCRequestBody = JSON.parse(arguments[1].body)
          const direct = requestBody.rateDetails.sort((a, b) => a.price - b.price).find(() => true)
          const fakeResponse = direct ? createPricingAPIResponse(direct.price, direct.currency) : {}
          return Promise.resolve(createFetchResponse(fakeResponse))
        } else {
          // @ts-ignore
          return fetch.apply(this, arguments)
        }
      }
    })(window, window.fetch)
  }

  render() {
    return null
  }
}
