import React, { useState } from 'react'
import styled from 'styled-components'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import { RoomsAndRates } from './RoomsAndRates'
import { useLocation } from 'react-router-dom'

const Container = styled.div`
  padding: 30px;
`

type Props = {
  language: string
}

export function Body({ language }: Props) {
  const query = new URLSearchParams(useLocation().search)
  const checkIn = query.get('check-in')
  const checkOut = query.get('check-out')
  const [dates, setDates] = useState({
    startDate: checkIn ? moment(checkIn).locale(language) : null,
    endDate: checkOut ? moment(checkOut).locale(language) : null,
  })
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null)

  return (
    <Container>
      <DateRangePicker
        displayFormat="Do MMM YY"
        startDate={dates.startDate}
        startDateId="your_unique_start_date_id"
        endDate={dates.endDate}
        endDateId="your_unique_end_date_id"
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => {
          setFocusedInput(focusedInput)
        }}
        onDatesChange={({ startDate, endDate }) => setDates({ startDate, endDate })}
        onClose={({ startDate, endDate }) => {
          const newCheckIn = startDate.format('YYYY-MM-DD')
          const newCheckOut = endDate.format('YYYY-MM-DD')
          if (newCheckIn !== checkIn || newCheckOut !== checkOut) {
            newCheckIn && query.set('check-in', newCheckIn)
            newCheckOut && query.set('check-out', newCheckOut)
            location.search = query.toString()
          }
        }}
      />
      <RoomsAndRates startDate={dates.startDate} endDate={dates.endDate} language={language} />
    </Container>
  )
}
