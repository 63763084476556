import React, { PureComponent, Fragment } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100vw;
  height: auto;
  background-color: dodgerblue;
  color: white;
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
`

const ContainerContent = styled.div`
  margin: 0 20px;
`

const Title = styled.h1`
  margin: 20px 0 40px 20px;
  font-size: 2em;
  font-weight: bold;
`

type Props = {
  integrationId: string
  hotelName: string
  language: string
}

export class Header extends PureComponent<Props> {
  render() {
    const { integrationId, hotelName, language } = this.props
    return (
      <Fragment>
        <Container>
          <ContainerContent>{integrationId}</ContainerContent>
          <br />
          <ContainerContent>Language: {language}</ContainerContent>
        </Container>
        <Title>{hotelName}</Title>
      </Fragment>
    )
  }
}
