import React, { PureComponent, Fragment } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import styled from 'styled-components'

import { Page } from './Page'

type Props = {}

export class App extends PureComponent<Props> {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/:integrationId/:hotelName/:language" children={<Page />} />
          <Route path="/" children={<Examples />} />
        </Switch>
      </BrowserRouter>
    )
  }
}

const Header = styled.div`
  color: white;
  background-color: darkred;
  flex: 0 1;
  font-size: 30px;
  text-align: center;
  line-height: 1.5em;
  padding-top: 100px;
  padding-bottom: 100px;
`

const Body = styled.div`
  padding: 30px;
  text-align: center;
`
const Title = styled.h1`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`

export class Examples extends PureComponent<Props> {
  render() {
    const UPLIFTNP = `${location.origin}/01DV3GT683TTQ0B2XJARPJPNWR/Betelguese%20Inn/en`
    const UPLIFTHCR = `${location.origin}/01DSJ80QRRY6RJ7ASETC523NCR/Rover Resort/en`
    const UPLIFTLC = `${location.origin}/01E25HDMCSZ7D38CNS5V316WMC/Chartreuse%20Hotel/en?check-in=2021-03-18&check-out=2021-03-20&realPriceCheckApi=true`

    return (
      <Fragment>
        <Header>
          Please specify integrationId, hotelName, language using the url
          https://domain/:integrationId/:hotelName/:language
        </Header>
        <Body>
          <Title>Example Client URLS</Title>
          <strong>UPLIFTNP:</strong> <a href={UPLIFTNP}>{UPLIFTNP}</a>
          <br />
          <strong>UPLIFTHCR:</strong> <a href={UPLIFTHCR}>{UPLIFTHCR}</a>
          <br />
          <strong>UPLIFTLC:</strong> <a href={UPLIFTLC}>{UPLIFTLC}</a>
          <br />
        </Body>
      </Fragment>
    )
  }
}
